@import 'import';
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-005;

  &:global(._fixed) {
    position: fixed;
    z-index: $z-index-spinner;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &:global(:not(._fixed)) {
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  &:global(._white) {
    background: $white;
  }
}
